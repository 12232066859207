import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import classNames from 'classnames';

import { getParentsBagdes } from './parentsBagdes';
import { getSurrogateBagdes } from './surrogateBadges';

import type { ProfileData } from '../MatchProfile.interface';
import type { Badge, BadgesProps } from './Badges.interface';
import type { ApiModel } from '@nodal/api';

const getBadges = (profile: ProfileData, role: ApiModel.UserRoleEnum) =>
  role === apiEnums.UserRoleEnum.Par
    ? getParentsBagdes(profile)
    : getSurrogateBagdes(profile);

export const Badges: FC<BadgesProps> = ({ profile, size, role }) => {
  const containerClassName = classNames('flex flex-wrap', {
    'gap-2': size === 'small',
    'gap-3': size === 'medium',
  });

  const labelClassName = classNames('font-medium text-grey-forest-700', {
    'text-xs leading-4': size === 'small',
    'text-sm leading-5': size === 'medium',
  });

  const badgeClassName = classNames(
    'flex justify-between items-center w-max bg-grey-forest-100 rounded-full',
    {
      'py-1 px-2 gap-1': size === 'small',
      'py-1.5 px-3 gap-3': size === 'medium',
    },
  );

  return (
    <div className={containerClassName}>
      {getBadges(profile, role).map(({ label, icon: Icon }: Badge, index) => {
        if (!label || label === 'Agency' || label === 'Independent')
          return null;

        return (
          <div className={badgeClassName} key={`${label}-${index})}`}>
            <div className="w-4 h-4 text-grey-forest-500">
              {Icon ? <Icon fill="currentColor" /> : null}
            </div>
            <p className={labelClassName}>{label}</p>
          </div>
        );
      })}
    </div>
  );
};
