import type { FC } from 'react';

import { apiEnums } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Select } from '@nodal/uikit/components/Select/Select';
import { useState } from 'react';

import { FaxForm } from './FaxForm';

import type { MedicalRequestFormsWrapperProps } from './MedicalRequestFormsWrapper.interface';

const thirdPartyPartnerOptions = [
  // {
  //   label: t('Datavant'),
  //   value: apiEnums.ThirdPartyProviderEnum.Datavant,
  // },
  // NOTE: This is commented out because MeMR is not yet available
  // TODO: Resolve during MeMr integration
  // Ref: https://linear.app/nodal-health/issue/NOD-423/frontendadmin-integrate-memr-submission-with-api
  // {
  //   label: t('meMR'),
  //   value: 'memr',
  // },
  {
    label: t('Fax'),
    value: apiEnums.ThirdPartyProviderEnum.Fax,
  },
];

export const MedicalRequestFormsWrapper: FC<
  MedicalRequestFormsWrapperProps
> = ({ values, editable }) => {
  const [thirdPartyProvider, setThirdPartyProvider] = useState(
    values?.third_party_provider || apiEnums.ThirdPartyProviderEnum.Fax,
  );

  return (
    <div className="space-y-4 w-full lg:w-step-view">
      <div className="p-8 bg-white rounded-lg border border-grey-forest-200">
        <div className="flex flex-col gap-4 pb-6 w-full text-left">
          <h2 className="text-2xl font-semibold leading-8 text-grey-forest-900">
            {t('Medical Record Request')}
          </h2>
          <Select
            name="third_party_provider"
            options={thirdPartyPartnerOptions}
            label={t('Third party partner')}
            disabled={!editable}
            onChange={(value) => setThirdPartyProvider(value)}
            value={thirdPartyProvider}
          />
        </div>
      </div>
      {/* NOTE: This is commented out because MeMR is not yet available
       * TODO: Resolve during MeMr integration
       * Ref: https://linear.app/nodal-health/issue/NOD-423/frontendadmin-integrate-memr-submission-with-api */}
      {/* {third_party_provider === 'memr' && (
        <MeMrForm editable={editable} values={values} />
      )} */}
      {/* {thirdPartyProvider === apiEnums.ThirdPartyProviderEnum.Datavant && (
        <DatavantForm editable={editable} values={values} />
      )} */}
      {thirdPartyProvider === apiEnums.ThirdPartyProviderEnum.Fax && (
        <FaxForm editable={editable} values={values} />
      )}
    </div>
  );
};
